import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import PageWrapper from '../components/pageWrapper'
import { renderRichText } from '../lib/richTextRender'

class Impressum extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const impressum = get(this.props, 'data.contentfulImpressum')

    return (
      <Layout>
        <PageWrapper>
          <Helmet title={siteTitle} />
          <div className="wrapper p-3">
            {renderRichText(impressum.content.json)}
          </div>
        </PageWrapper>
      </Layout>
    )
  }
}

export default Impressum

export const pageQuery = graphql`
  query impressumQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulImpressum(slug: { eq: "Impressum" }) {
      content {
        json
      }
    }
  }
`
